//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-692:_5136,_9612,_2112,_6016,_7968,_2836,_8652,_7540;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-692')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-692', "_5136,_9612,_2112,_6016,_7968,_2836,_8652,_7540");
        }
      }catch (ex) {
        console.error(ex);
      }